import { Injectable, inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AppConfigService } from '@fuse/services/config';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root',
})
export class VersionCheckService {
    private _swUpdate: SwUpdate = inject(SwUpdate);
    private _config: AppConfigService = inject(AppConfigService);
    private _logger: LoggerService = inject(LoggerService);

    public checkForUpdates(): void {
        if (this._config.isVersionUpdated()) {
            this._config.unsetVersionUpdated();
            //TODO add this to localization
            this._logger.info('New version', 'Refreshed screen because of new app version');
        }
        //check version updated
        this._swUpdate.versionUpdates.subscribe((event) => {
            console.log(`Version update event: ${event.type}`);

            if (event.type === 'VERSION_READY') {
                console.log('New version available. Updating...');
                this._swUpdate
                    .activateUpdate()
                    .then(() => {
                        console.log('Update activated. Reloading page...');
                        this._config.setVersionUpdated();
                        document.location.reload();
                    })
                    .catch((error) => {
                        console.error('Error activating update:', error);
                    });
            }
        });
    }
}
