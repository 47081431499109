import { DecimalPipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
    EnvironmentProviders,
    ErrorHandler,
    Provider,
    inject,
    isDevMode,
    provideAppInitializer,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    ExtraOptions,
    PreloadAllModules,
    Router,
    provideRouter,
    withComponentInputBinding,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
    withPreloading,
    withRouterConfig,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import {
    DateTimeAdapter,
    LuxonDateTimeAdapter,
    MTX_DATETIME_FORMATS,
    MTX_LUXON_DATETIME_FORMATS,
} from '@fuse/components/datetime';
import { TranslocoService, provideTransloco } from '@jsverse/transloco';
import { AppComponent } from './app/app.component';

import { provideHttpClient } from '@angular/common/http';
import {
    LuxonDateAdapter,
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
    MatLuxonDateAdapterOptions,
} from '@angular/material-luxon-adapter';
import { provideFuse } from '@fuse';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import * as Sentry from '@sentry/angular';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/environment';
import { provideQuillConfig } from 'ngx-quill';
import { firstValueFrom } from 'rxjs';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash: true,
};

export const provideSharedPipes = (): Array<Provider | EnvironmentProviders> => {
    return [
        //TODO NOt to be used, should use custom
        {
            provide: DecimalPipe,
            useClass: DecimalPipe,
        },
    ];
};

if (environment.sentryUrl.length > 0) {
    Sentry.init({
        dsn: environment.sentryUrl,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/app.tradingmetrics\.com/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // results in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: 1.0,
    });
}

bootstrapApplication(AppComponent, {
    providers: [
        provideAnimations(),
        provideHttpClient(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
            // TODO added, check if correct
            // in place of initialNavigation: 'enabledBlocking'
            withEnabledBlockingInitialNavigation(),
            withRouterConfig({
                paramsInheritanceStrategy: 'always',
                onSameUrlNavigation: 'reload',
            }),
            withComponentInputBinding()
        ),
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        provideSharedPipes(),

        { provide: MTX_DATETIME_FORMATS, useValue: MTX_LUXON_DATETIME_FORMATS },
        {
            provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: false, firstDayOfWeek: 1 } as MatLuxonDateAdapterOptions,
        },
        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
        },
        //TODO https://stackblitz.com/edit/angular-7e4dya?file=src%2Fapp%2Fdatepicker-locale-example.ts
        {
            provide: DateTimeAdapter,
            useClass: LuxonDateTimeAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'MMM d, yyyy',
                },
                display: {
                    dateInput: 'MMM d, yyyy',
                    monthYearLabel: 'MMM yyyy',
                    dateA11yLabel: 'MMMM d, yyyy',
                    monthYearA11yLabel: 'MMMM yyyy',
                },
            },
        },

        //sentry
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        provideAppInitializer(() => {
            inject(Sentry.TraceService);
        }),

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English',
                    },
                    {
                        id: 'tr',
                        label: 'Turkish',
                    },
                ],
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
        provideAppInitializer(() => {
            const translocoService = inject(TranslocoService);
            const defaultLang = translocoService.getDefaultLang();
            translocoService.setActiveLang(defaultLang);

            return firstValueFrom(translocoService.load(defaultLang));
        }),

        provideHotToastConfig({
            reverseOrder: true,
            dismissible: true,
            autoClose: true,
            position: 'top-center',
        }),

        //quill
        provideQuillConfig({
            modules: {
                syntax: false,
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    ['blockquote'],

                    [{ header: 1 }, { header: 2 }], // custom button values
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

                    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ font: [] }],
                    [{ align: [] }],

                    ['clean'], // remove formatting button

                    ['link', 'image'], // link and image
                ],
            },
            customOptions: [
                {
                    import: 'formats/font',
                    whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace'],
                },
            ],
        }),

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
                enabled: environment.mock,
            },
            fuse: {
                layout: 'modern',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-brand',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),

        provideServiceWorker('./tm-sw.js', {
            //ngsw-worker.js
            enabled: true,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        provideHttpClient(),
        provideTransloco({
            config: {
                availableLangs: ['en', 'es', 'de'],
                defaultLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader,
        }),

        // importProvidersFrom(BrowserModule,
        //     LayoutModule,
        //     // 3rd party modules that require global configuration via forRoot
        //     MtxDateFnsDatetimeModule,
        // }),
        // provideRouter(appRoutes,
        //     withComponentInputBinding(),
        //     // this is in place of scrollPositionRestoration: 'disabled',
        //     withInMemoryScrolling({
        //         scrollPositionRestoration: 'disabled',
        //     }),
        //     // in place of initialNavigation: 'enabledBlocking'
        //     withEnabledBlockingInitialNavigation(),
        //     // same configuration
        //     withRouterConfig({
        //         paramsInheritanceStrategy: 'always',
        //         onSameUrlNavigation: 'reload'
        //     }),
        //     // in place of  preloadingStrategy: PreloadService,
        //     withPreloading(PreloadAllModules)
        // ),
        // { provide: LocationStrategy, useClass: HashLocationStrategy },
        // {
        //     provide: MAT_DATE_LOCALE,
        //     useValue: enUS,
        // },
        // {
        //     provide: MTX_DATETIME_FORMATS,
        //     useValue: {
        //         parse: {
        //             dateInput: 'yyyy-MM-dd',
        //             monthInput: 'MMMM',
        //             yearInput: 'yyyy',
        //             timeInput: 'HH:mm',
        //             datetimeInput: 'yyyy-MM-dd HH:mm',
        //         },
        //         display: {
        //             dateInput: 'yyyy-MM-dd',
        //             monthInput: 'MMMM',
        //             yearInput: 'yyyy',
        //             timeInput: 'HH:mm',
        //             datetimeInput: 'yyyy-MM-dd HH:mm',
        //             monthYearLabel: 'yyyy MMMM',
        //             dateA11yLabel: 'LL',
        //             monthYearA11yLabel: 'MMMM yyyy',
        //             popupHeaderDateLabel: 'MMM dd, E',
        //         },
        //     },
        // },
    ],
}).catch((err) => console.error(err));

console.log('Angular app bootstrapped');

// ✅ Register Firebase Service Worker AFTER Angular initializes
// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase/' })
//         .then((registration) => {
//             console.log('Firebase SW registered:', registration);
//         })
//         .catch((err) =>{
//             console.error('Firebase SW registration failed:', err);
//         });
// }
