import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MessagingService } from './core/utils/messaging.service';
import { VersionCheckService } from './core/utils/version-check.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
    private _versionCheckService: VersionCheckService = inject(VersionCheckService);
    private _messagingService: MessagingService = inject(MessagingService);

    ngOnInit(): void {
        this._versionCheckService.checkForUpdates();
        this._messagingService.listenForMessages();
    }
}
